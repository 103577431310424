import React from "react";

import { Container, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Layout from "../components/general/Layout";
import gsc_logo from "../../static/assets/gsc_logo.svg";
import { Link } from "gatsby";
const useStyles = makeStyles(theme => ({
    gridContainer: {
        margin: theme.spacing(2, 0)
    },
    logo: {
        width: "12rem",
        padding: "15px"
    },
    text: {
        padding: "15px",
        "padding-top": "5px",
        "text-align": "justify"
    }
}));

const Home = () => {
    const classes = useStyles();
    return (
        <Layout>
            <Container maxWidth="xl" className={classes.gridContainer}>
                <Grid
                    container
                    direction="column"
                    justify="space-evenly"
                    alignItems="center"
                >
                    <img
                        className={classes.logo}
                        src={gsc_logo}
                        alt="Glasgow Science Center"
                    />
                    <h2>Welcome</h2>
                    <Container>
                        <p className={classes.text}>
                            Welcome to the Glasgow Science Centre augmented
                            reality treasure hunt! Find the markers throughout
                            the science centre, and try to collect them all!
                            Please return to reception when all experiences have
                            been unlocked, and something good may happen!
                        </p>
                    </Container>
                    <Link to="/experiences">
                        <Button variant="contained" color="primary">
                            Get Started
                        </Button>
                    </Link>
                </Grid>
            </Container>
        </Layout>
    );
};

export default Home;
